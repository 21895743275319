@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @font-face {
  font-family: 'Mulish';
  src: url('/fonts/mulish/Mulish-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;

} */
.video_thumbnail_cont .video_thumbnail_image {
  transition: transform 0.5s ease; /* Apply transition to both hover and non-hover states */
}

.video_thumbnail_cont:hover .video_thumbnail_image{
  transform: scale(1.15);
  /* transition: transform 0.5s ease; */
}

.font-open-sans-bold {
  font-family: 'Open Sans',
    sans-serif;
  font-weight: 700;
}

.font-open-sans-500 {
  font-family: 'Open Sans',
    sans-serif;
  font-weight: 500;
}

.MuiAlert-icon svg {
  fill: #fff;
}

div.MuiInputBase-sizeSmall:hover {
  border-color: #bdc3d1 !important;
}

.date-picker fieldset, .category-select fieldset {
  border-color: #bdc3d1 !important;
  border-width: 1px !important;
}

.date-picker label.MuiInputLabel-sizeSmall, .category-select label.MuiInputLabel-sizeSmall {
  font-family: 'Mulish' !important;
  color: #696c74 !important;
  font-size: 14px !important;
  line-height: 21px !important;
}

.date-picker div.MuiInputBase-sizeSmall {
  height: 40px;
}

.date-picker label.MuiInputLabel-shrink, .category-select label.MuiInputLabel-shrink {
  font-size: 16px !important;
}

.date-picker input {
  font-size: 14px !important;
  font-family: 'Mulish' !important;
}

.date-picker input::placeholder {
  font-size: 14px !important;
  font-family: 'Mulish' !important;
}

.search-date-picker fieldset {
  border-width: 0 !important;
}

.search-date-picker svg {
  fill: #9ca0ab !important;
}

.category-select .MuiSelect-select {
  font-size: 14px !important;
  font-family: 'Mulish' !important;
}

.check-box span.MuiCheckbox-root {
  padding: 0;
}

.mui-checkbox span.MuiFormControlLabel-label {
  padding-top: 2px;
  font-family: mulish;
  font-size: 14px;
  color: #696c74;
}
.mui-checkbox.mui-checkbox-border svg.MuiSvgIcon-root {
  fill: #bdc3d1;
}

.category-select .MuiFormControl-root {
  width: 100%;
  margin: 0 0 15px 0 !important;
}

#searchBarContainer .searchInp {
  border-right: none !important;
}

#searchBarContainer .dropBtn {
  border-left: none !important;
}

.des-editor .ck-placeholder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 14px;
  font-family: mulish;
  color: #696c74;
}

.des-editor.value-required .ck-placeholder::after {
  content: "*";
  color: #dc3545;
  position: relative;
  left: 78px;
  font-size: 16px;
  line-height: 18px;
}

.MuiTooltip-tooltip {
  height: 25px;
  background: #000 !important;
}

.MuiTooltip-arrow {
  color: #000 !important;
}

.inner-html-ptag p {
  margin-bottom: 0;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

a {
  text-decoration: none !important;
}

.app-card {
  background-image: radial-gradient(at 50% 100%, #a1c9e6 3%, #bee8ed 100%), linear-gradient(to bottom, rgba(255, 255, 255, .25) 0%, rgba(0, 0, 0, .25) 100%);
  background-blend-mode: screen, overlay;
  cursor: pointer;
}

.app-card:hover {
  box-shadow: #00000026 0 5px 10px;
}

.profileOpened .MuiPaper-root, .appsOpened .MuiPopover-paper, #orgaizationDropdown .MuiPaper-root {
  top: 50px !important;
  border-radius: 0 !important;
}

#searchDropdown1 .MuiPaper-root {
  border-radius: 0 !important;
  transform: translate(-1px, 5px) !important;
}

.organizationListOpened .MuiPopover-paper {
  left: 2px !important;
}

.social-media-checkboxes .MuiCheckbox-root {
  padding: 2px 9px;
  font-size: 10px;
}

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.combo-box ul {
  border: 1px solid #bdc3d1;
  border-radius: 0 0 3.75px 3.75px;
  border-top: none;
  margin-top: 0 !important;
  padding: 0 !important;
}

.combo-box ul li {
  padding: 8px 12px !important;
  /* border-bottom: 1px solid #bdc3d1; */
}

.combo-box ul li:last-child {
  /* border-bottom: none; */
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgb(192, 192, 192);
  border-radius: 6px;
}

.gallery-sec::-webkit-scrollbar-thumb {
  background: #0000002b;
  border-radius: 6px;
}

.ck.ck-editor__main>.ck-editor__editable{
height: 15vh;
min-height: 8rem;
}


.rdw-image-modal{
  right: 5px !important;
}

._container_video_image{
  transition: 0.2s ease-in;
}
._container_video:hover ._container_video_image{
  transform: scale(1.1);
}

.videos_cont{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
  grid-column-gap : 16px;
  grid-row-gap:30px
}



.filemanager .thmb {
  display: inline-block;
  /* width: 25% !important; */
  /* min-width: 250px; */
min-height: 250px;
  max-width: 230px;
  margin-left: 16px;
  background-color: #fff;
  border-radius: 2px;
  padding: 10px !important;
  margin-bottom: 16px;
  position: relative;
}
.filemanager .thmb:after {
  clear: both;
  display: block;
  content: '';
}
.filemanager .ckbox {
  position: absolute;
  top: 15px;
  left: 15px;
  display: none;
}
.filemanager .ckbox span:before {
  border: 0;
}
.filemanager .fm-group {
  position: absolute;
  top: 15px;
  right: 15px;
  display: none;
}
.filemanager .fm-toggle {
  padding: 1px 4px;
  line-height: normal;
  background-color: #fff;
  border-radius: 2px;
  border: 0;
}
.filemanager .fm-toggle:hover,
.filemanager .fm-toggle:focus,
.filemanager .fm-toggle:active {
  background-color: #fff !important;
}
.filemanager .fm-menu {
  border-color: #dbdfe6;
  min-width: 120px;
}
.filemanager .fm-menu a {
  color: #696c74;
}
.filemanager .fm-menu i {
  margin-right: 7px;
  width: 16px;
  font-size: 14px;
}
.filemanager .thmb-prev {
  background-color: #eee;
  overflow: hidden;
}
.filemanager .thmb-prev a {
  display: block;
}
.filemanager .fm-title {
  margin-bottom: 2px;
  font-weight: 500;
}
.filemanager .fm-title a {
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: block;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.row:before,
.row:after{
  content: " ";
  display: table;
}

.row:after{
  clear: both;
}


.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_ne, .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se{
  right: 0 !important;
  left: unset !important;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  
}


.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #bdbebe;
  border-top: 1px solid #bdbebe;
}

.scroll-transition{
  transition: scroll-behavior 0.5s
}

.lineclamp{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
  -webkit-box-orient: vertical;
}
span{
  text-justify: inter-word;
}

.row>*{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.rdrMonthAndYearPickers select{
  accent-color: red;
}
.rdrMonthPicker option,.rdrMonthAndYearPickers select option {
  /* padding: unset !important; */
  text-align: left !important;
  padding: 22px !important;
  font-size: 15px;
  
}


.rdrMonthPicker option:checked ,.rdrMonthAndYearPickers select option:checked {
  background-color: #1d1d1d; /* Replace with your desired accent color */
  color: #fff; /* Text color for selected option */
}

.rdrMonthPicker option:hover,.rdrMonthAndYearPickers select option:hover{
background-color: #a1c9e6 !important;
}